import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { reposicaoStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import ShowPopup from '@/components/ShowPopup.vue';
let Reposicao = class Reposicao extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Data/Hora', value: 'data_hora' },
            { text: 'Tipo', value: 'tipo' },
            { text: 'Aprovada', value: 'aprovada' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Livro', value: 'livro_nome' },
            { text: 'Unidade', value: 'descricao' },
            { text: 'Professor', value: 'full_name' },
            { text: 'Sala', value: 'nome' },
            { text: 'Turma', value: 'codigo_referencia' },
            { text: 'Número da Turma', value: 'codigo_referencia_num' },
            { text: 'Status', value: 'status' },
            { text: 'Pagamento', value: 'financeiro_status' },
        ];
        this.tipoOptions = [
            { text: 'Reposição', value: 0 },
            { text: 'Aula Extra', value: 1 },
            { text: 'Reposição em Turma', value: 2 },
        ];
        this.search = '';
        this.loading = false;
        this.items = [];
        this.selectPeriodDate = null;
        this.selectedTipo = null;
    }
    async buscar() {
        this.loading = true;
        this.items = await reposicaoStore.getReposicoesAlunos({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
        this.items = this.selectedTipo !== null
            ? this.items.filter((item) => item.tipo === this.selectedTipo)
            : this.items;
        this.loading = false;
    }
    async iniciar_aula(item) {
        if (item.aula_online_url) {
            window.open(item.aula_online_url, '_blank').focus();
        }
    }
};
Reposicao = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            ShowPopup,
        },
    })
], Reposicao);
export default Reposicao;
